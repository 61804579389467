<template>
	<div>Request</div>
</template>

<script>
export default {
	name: "request",
	mounted() {
		document.title = "Request | magardTELE";
	}
}
</script>